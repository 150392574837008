.header {
  overflow-x: auto;
  z-index: 10;

  height: var(--header-height);
  scrollbar-width: thin;
  scrollbar-color: var(--color-fg-scroll) var(--grey-500);
  background: var(--color-bg-header);
}

.header__scrollable {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 101;
}

.header-logo-wrapper {
  min-width: calc(var(--nav-open-width) - 30px);
}

.header-logo {
  height: 26px;
}

.header-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.header-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 25px;
}

button.header-item {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}

.header-item {
  font-size: 14px;
  color: var(--color-fg-base);
  white-space: nowrap;
}

.header-item__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.header-item__top {
  margin-bottom: 5px;
}

.header-item__bottom {
  min-height: 14px;
  color: var(--color-bg-nav-active);
}

.header-item__icon {
  height: 32px;
  margin: 0 10px;
}

.header-ticker {
  margin-right: auto;
  margin-left: 0;
}

.header-button {
  margin: 0 8px;
}

.header-button_disabled {
  opacity: 0.4;
  pointer-events: none;
}

.header-button__icon {
  height: 27px;
  fill: var(--grey-100);
}

.header-button__icon:hover {
  fill: var(--blue-400);
}

.header__group_delimiter {
  margin-left: 60px;
}

@media (max-width: 1280px) {
  .header__scrollable {
    padding: 15px;
    min-width: 820px;
  }

  .header-item {
    margin-left: 10px;
  }

  .header__group_delimiter {
    margin-left: 20px;
  }
}
@media (max-width: 1024px) {
  .header-logo-wrapper {
    min-width: 150px;
  }
}
