.map-controls {
  --aircraft-lookup-form-width: 250px;
  --aircraft-lookup-form-height: var(--map-control-button-size);
  --aircraft-lookup-form-overlap: 10px;
  --aircraft-lookup-form-animation: 0.4s;
  --aircraft-lookup-clip-path-animation: 0.1s;
}

.aircraft-lookup-form {
  position: absolute;
  width: var(--aircraft-lookup-form-width);
  height: var(--aircraft-lookup-form-height);
  left: var(--aircraft-lookup-form-overlap);
  top: 0;
  z-index: 0;

  display: flex;
  align-items: center;
  padding: 0 calc(var(--aircraft-lookup-form-overlap) + var(--aircraft-lookup-form-overlap)) 0
    var(--aircraft-lookup-form-overlap);
  background-color: var(--color-bg-base);
  border-radius: var(--border-radius-button) 0 0 var(--border-radius-button);
  transition: transform var(--aircraft-lookup-form-animation) ease var(--aircraft-lookup-clip-path-animation);
  transform: translate3d(100%, 0, 0);
  will-change: transform;
  backface-visibility: hidden;
}

.aircraft-lookup-form_open {
  transition: transform var(--aircraft-lookup-form-animation) ease;
  transform: translate3d(0, 0, 0);
}

.map-controls__aircraft-lookup-form {
  position: absolute;
  right: 0;
  width: calc(var(--map-control-button-size) + var(--aircraft-lookup-form-width));
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  clip-path: inset(0px 1px 0px 0px round var(--border-radius-button));
  transition: clip-path var(--aircraft-lookup-clip-path-animation) ease;
}

.map-controls__aircraft-lookup-form .aircraft-lookup-form,
.map-controls__aircraft-lookup-form .map-control {
  pointer-events: all;
}

.map-controls__aircraft-lookup-form_open {
  clip-path: inset(0px 1px -140px 0px round var(--border-radius-button));
  transition: clip-path var(--aircraft-lookup-clip-path-animation) ease var(--aircraft-lookup-form-animation);
}
