.flight-history-map .flight-tag-popup .mapboxgl-popup-tip {
  display: none;

}

.flight-history-map .flight-tag-popup .tag_wrapper {
  bottom: 10px;
  right: 15px;
}

.flight-history-map .flight-tag-popup .mapboxgl-popup-content {
  pointer-events: none;
  background-color: transparent;
  box-shadow: none;
}