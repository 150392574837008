.view-3d {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.view-3d__label {
  font-weight: 300;
  color: var(--grey-100);
  margin-left: 10px;
}

.view-3d__switch {
  position: relative;
  max-width: 40px;
  width: 100%;
  padding: 11px 0;
  border-radius: 10px;
  border: none;
  background: var(--grey-400);
}

.view-3d__switch span {
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 10%;
  border-radius: 50%;
  background: var(--blue-500);
  transition: left 0.3s, background 0.3s;
}

.view-3d__switch--active {
  background: var(--blue-500);
}

.view-3d__switch--active span {
  background-color: var(--grey-400);
  left: 50%;
}