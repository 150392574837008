.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  --base-min-width: 300px;
}

.form-group__name {
  font-family: inherit;
  font-size: 13px;
  margin-bottom: 10px;
  color: var(--color-fg-form-group);
  padding-left: 10px;
}

.form-group__items {
  position: relative;
}

.form-group_filled > .form-group__items {
  background-color: var(--color-bg-form-group);
  border-radius: 4px;
}

.form-group-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--base-min-width), 1fr));
  column-gap: 15px;
  row-gap: 10px;
  color: var(--color-fg-form-field-label);
  border-radius: 4px;
}

.form-group-row_filled {
  background-color: var(--color-bg-form-group);
}

.form-group-row_gap {
  margin-bottom: 15px;
}

.form-group-row_button {
  display: flex;
  flex-flow: row wrap;
  column-gap: 0;
  row-gap: 0;
}

.form-group-row_button .button {
  margin-right: 15px;
}

.form-group-row_button .button:last-child {
  margin-right: 0;
}

.form-group-row_padding {
  padding: 10px;
}

.form-group-row_checkbox {
  min-height: 60px;
}

.form-group-row_checkbox-single {
  min-height: 75px;
}

.form-group-row_checkbox_no-label {
  padding-top: 17px;
}

.form-group-row_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  column-gap: 0;
  row-gap: 0;
}

.form-group-row_flex > .formfield:first-child {
  margin-right: 10px;
}

.form-group-row_flex > .formfield:last-child {
  margin-left: 10px;
}

.form__reminder {
  min-height: 32px;
  color: var(--color-fg-highlight);
  line-height: 1.5;
  font-size: 13px;
  margin: 0;
  margin-top: auto;
  font-style: italic;
}

.form__reminder_nowrap {
  white-space: nowrap;
}

.form__reminder_centered {
  margin-top: 0;
}

.form__reminder_flex {
  display: flex;
  align-items: center;
}

.form__reminder_input {
  color: var(--color-fg-form-field-label);
  margin-top: 5px;
}

.form__spinner {
  margin: auto;
  display: block;
}

.form-group-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  row-gap: 20px;
}

.form-group-column__button {
  margin: 20px auto;
}
