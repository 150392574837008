.vt-datepicker__wrapper {
  display: flex;
}

.vt-datepicker__wrapper:first-child {
  margin-bottom: 10px;
}

.vt-datepicker__block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.vt-datepicker__block label {
  font-size: 13px;
  line-height: 1.15;
  color: var(--color-fg-form-field-label);
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vt-datepicker__block input {
  height: 32px;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
  font-family: inherit;
  font-weight: 400;
  background: var(--color-bg-input);
  color: var(--color-fg-input);
  border: none;
}

.vt-datepicker__wrapper .vt-datepicker__block:first-child {
  margin-right: 10px;
}
