.map-controls {
  --map-control-button-size: 42px;
}

.map-control {
  width: var(--map-control-button-size);
  height: var(--map-control-button-size);
  background-color: var(--grey-300);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: var(--border-radius-button);
  box-shadow: var(--shadow-button);
  cursor: pointer;
  padding: 0;
  position: relative;
}

.map-control__wrap {
  position: relative;
  width: var(--map-control-button-size);
  height: var(--map-control-button-size);
  box-shadow: var(--shadow-button), inset 0 0 5px 5px rgba(0, 0, 0, 0.29);
  border-radius: var(--border-radius-button);
}

.map-control__wrap .map-control {
  box-shadow: none;
}

.pointerfocus .map-control:focus {
  outline: none;
}

.map-control__icon {
  max-width: 100%;
  max-height: 100%;
}

.map-control__icon-filters {
  max-width: 60%;
  max-height: 60%;
}

.map-control__icon-path {
  fill: var(--grey-100);
}

.map-control:hover .map-control__icon-path {
  fill: var(--blue-400);
}

.map-control_active {
  background-color: var(--blue-400);
}
.map-control_active .map-control__icon-path {
  fill: var(--grey-300);
}

.map-control_active:hover {
  background-color: var(--grey-300);
}
.map-control_active:hover .map-control__icon-path {
  fill: var(--blue-400);
}

.map-control:disabled {
  cursor: auto;
}
.map-control:disabled .map-control__icon-path {
  fill: var(--grey-200);
}

/* Combined button */

.map-control_combined-wrap {
  box-shadow: var(--shadow-button);
  border-radius: var(--border-radius-button);
  overflow: hidden;
}

.map-control_combined {
  position: relative;
  box-shadow: none;
  border-radius: 0;
}

.map-control_combined:not(:first-child)::before {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  width: 38px;
  left: 2px;
  top: 0;
  background-color: var(--grey-200);
}

/* Info button */

.map-control_type_info {
  width: 20px;
  height: 20px;
  position: relative;
}

.map-control_type_info:hover::before {
  min-width: 100px;
  padding: 3px 30px 3px 5px;
  width: auto;
}

.map-control_type_info::before {
  transition: all 0.2s;
  content: attr(data-info);
  width: 0;
  min-width: 0;
  padding: 3px 0;
  overflow: hidden;
  position: absolute;
  height: 24px;
  line-height: 20px;
  top: -2px;
  right: -3px;
  white-space: nowrap;
  z-index: -1;
  color: var(--grey-800);
  background-color: rgba(226, 226, 226, 0.8);
  border-radius: 2px;
  border-top-right-radius: var(--border-radius-button);
  border-bottom-right-radius: var(--border-radius-button);
}

.map-control_badge::after {
  --badge-size: 20px;
  content: attr(data-badge);
  width: var(--badge-size);
  height: var(--badge-size);
  line-height: var(--badge-size);
  font-size: 11px;
  font-family: var(--default-font-family);

  border-radius: 50%;
  background-color: var(--blue-400);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: -8px;
  right: -8px;
}
