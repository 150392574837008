.map {
  width: 100%;
  height: var(--content-height);
  flex: 1;
  position: relative;
}

.map-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: var(--content-bottom-height);
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;
  padding: 15px;
  padding-bottom: 25px;
}

.map_disclaimer .map-controls {
  padding-bottom: 32px;
}

.map-controls_hall {
  justify-content: center;
  height: 100%;
  top: auto;
  padding: 8px 15px;
  z-index: 1001;
  bottom: 0;
}

.map-controls_hall .map-controls__control {
  margin-top: 0;
  opacity: 0.15;
  background-color: var(--grey-700);
  transition: 0.15s;
}

.map-controls_hall:hover .map-controls__control {
  opacity: 0.8;
  background-color: var(--grey-700);
}

.map-controls_hall .map-controls__layers {
  margin-bottom: auto;
}

.map-controls__control {
  margin-top: 15px;
  pointer-events: all;
}
.map-controls__control:first-child {
  margin-top: 0;
}
.map-controls__control-br {
  margin-top: auto;
}

.map-controls-modal {
  position: absolute;
  top: 0;
  right: 200px;
  width: 200px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid black;
  z-index: 1000;
}

.map-controls-modal_zoom {
  top: auto;
  bottom: 200px;
}

.map-disclaimer {
  position: absolute;
  bottom: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  height: 26px;
  line-height: 24px;
  font-size: 13px;
  width: 100%;
}

.map_type_Dark ~ .map-disclaimer {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.1);
}
