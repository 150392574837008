.flight-history-datepicker .rs-input-group-addon,
.flight-history-datepicker input {
  height: 32px !important;
}

.flight-history-datepicker {
  display: block !important;
}

.flight-history {
  position: fixed;
  z-index: 10000;
  max-width: 340px;
  width: 100%;
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
  background: var(--color-bg-header);
}

.flight-history-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 25px;
}

.flight-history .container-search {
  width: 100%;
}

.flight-history-header__close {
  position: absolute;
  right: 5px;
}

.flight-history-header__title {
  color: var(--blue-400);
  font-weight: bold;
  font-size: 16px;
}

.flight-history--hidden .flight-history-body {
  display: none;
}

.flight-history .custom-select__select {
  max-height: 120px;
}

.flight-history-loader {
  padding: 2px 0;
  position: relative;
  width: 100%;
  background-color: var(--color-fg-nav-menu);
}

.flight-history-loader__bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--blue-400);
  transition: all 0.3s;
}

.history-location-search {
  width: 100%;
}

.history-location-search input {
  padding: 4.3px 10px;
  height: 100%;
}

.history-location-search .container-search__icon {
  top: 5px;
}

.flight-history-block {
  position: relative;
  margin-bottom: 5px;
  padding: 24px;
  border-bottom: 2px solid var(--grey-300);
}

.flight-history-tab {
  margin-bottom: 5px;
  padding: 10px 24px 10px 24px;
  border-bottom: 2px solid var(--grey-300);
}

.flight-history-tab .accordion-tab__content {
  margin-top: 15px;
  margin-bottom: 15px;
}

.flight-history-block:last-child {
  margin-bottom: 0;
  border: none;
}

.flight-history-block__header {
  position: absolute;
  z-index: 10;
  top: -3px;
  right: 0;
}

.flight-history-block__title {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue-400);
}

.flight-history-block__reset {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-bg-header);
  padding: 0 4px;
  border-radius: 8px;
  background-color: var(--color-fg-nav-menu);
}

.flight-history-data {
  margin-bottom: 20px;
}

.flight-history-data:last-child {
  margin-bottom: 0;
}

.flight-history .accordion-tab__label:before {
  top: 6px;
}

.flight-history-data__label {
  font-size: 14px;
  margin-bottom: 9px;
  color: var(--color-fg-form-field-label);
}

.flight-history-data__center-point {
  display: flex;
  align-items: center;
}

.flight-history-data__button {
  padding: 1px 12px;
  border-radius: 4px;
  color: black;
  background-color: var(--color-fg-nav-menu);
}

.flight-history-data__button--active {
  background-color: var(--blue-400);
}

.flight-history-data__center-point__button {
  margin-right: 8px;
}

.flight-history-data__filter {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.flight-history-data__filter-block {
  max-width: 50%;
  width: 100%;
}

.flight-history-data__filter-block:first-child {
  margin-right: 10px;
}

.flight-history-data__filter-block input {
  width: 100%;
  outline: none;
}

.flight-history-block__button {
  background-color: var(--grey-300);
  border-radius: 2px;
  padding: 5px;
  border: none;
  width: 100%;
  color: var(--grey-500);
}

.flight-history-data__filter-submit {
  margin-top: 25px;
}

.flight-history-block__button--primary {
  background-color: var(--blue-400);
  color: black;
}

.flight-history-block--export .flight-history-block__button {
  margin-top: 15px;
}

.flight-history-w-full {
  width: 100%;
}

.flight-history-mr-5 {
  margin-right: 5px;
}

.flight-history-mb-5 {
  margin-bottom: 5px;
}

.flight-history-mb-15 {
  margin-bottom: 15px;
}
