.vt-button {
  background-color: var(--grey-300);
  border-radius: 3px;
  padding: 8px;
  border: none;
  display: block;
  color: var(--grey-150);
  border: 1px solid transparent;
  transition: color 0.3s, background-color 0.3s, background 0.3s, opacity 0.1s;
}

.vt-button.rounded {
  border-radius: 8px;
}

.vt-button:active {
  opacity: 0.7;
}

.vt-button.sm {
  padding: 3px 8px;
}

.vt-button.xl {
  padding: 12px;
}

.vt-button.primary {
  color: var(--grey-700);
  background-color: var(--blue-400);
}

.vt-button.outline {
  background-color: transparent;
  border-color: var(--grey-200);
  color: var(--grey-200);
}