.navigation__list {
  margin: 0;
  padding: 0;
  margin-bottom: 27px;
}

.navigation-item {
  list-style-type: none;
}

.navigation-item__heading {
  color: var(--color-fg-nav-heading);
  color: var(--grey-150);
  padding-left: 30px;
  padding-right: 20px;
  margin-bottom: 4px;
}

.navigation-item__link-list {
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
}

.navigation-item__link {
  display: block;
  color: var(--color-fg-nav-heading);
  fill: var(--color-fg-nav-heading);
  text-decoration: none;
  border-radius: 5px;
  line-height: 18px;
  padding: 4px;
  padding-right: 10px;
  padding-left: 40px;
  transition: color 0.1s cubic-bezier(0.47, 0, 0.75, 0.72);
}

.navigation-item__link:hover:not(.navigation-item__link_disabled) {
  color: var(--blue-400);
  transition-duration: 0.05s;
}

.navigation-item__link_disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.navigation-item__link_active {
  color: var(--blue-400);
  fill: var(--blue-400);
  font-weight: 500;
}

.navigation-item__link-icon {
  display: inline-block;
  height: 16px;
  position: relative;
  left: 5px;
  top: 2px;
}

@media screen and (max-width: 1280px), screen and (max-height: 968px) {
  .navigation-item__heading {
    font-size: 15px;
  }
  .navigation-item__link {
    padding-left: 35px;
  }
}
