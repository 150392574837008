:root {
  --loader-horizontal-height: 3px;
}

.loader-horizontal {
  width: 100%;
  height: var(--loader-horizontal-height);
  display: inline-block;
  position: relative;
  background: transparent;
  overflow: hidden;
}

.loader-horizontal::after {
  content: '';
  width: 30%;
  height: var(--loader-horizontal-height);
  background: var(--blue-500);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  left: 0;
  transform: translateX(-100%);
}

.loader-horizontal_animate::after {
  animation: anim_loader_horizontal 2s linear 0.1s infinite;
}

@keyframes anim_loader_horizontal {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
