.noise {
  --modal-width: 340px;
}

.noise_section {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.noise_section .container-search {
  width: 100%;
  margin: 0;
  position: relative;
}

.noise_header {
  color: var(--color-fg-highlight);
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.noise_header-icon {
  height: 30px;
  margin-right: 10px;
}

.noise__delimiter {
  margin: 20px 0 0;
}

.noise_section .formfield {
  margin-bottom: 10px;
}

.noise_section .formfield_checkbox {
  margin-bottom: 0;
}

.noise_section .formfield .input {
  font-size: 12px;
}

.noise_section .formfield .formfield__label {
  font-size: 12px;
  margin-bottom: 5px;
}

.noise_section .formfield .input__icon {
  display: none;
}

.noise_section .formfield.noise-input_icon-visible .input__icon {
  display: flex;
  right: -6px;
  top: 0;
}

.noise_section .formfield .input__error {
  left: 0;
  bottom: -16px;
}

.noise_section .form-group-row_flex > .formfield:first-child {
  margin-right: 0;
}

.noise_section .formfield__select {
  margin-bottom: 20px;
}

.noise_section .form-group-row_button .button {
  margin-right: 10px;
}

.noise_section .form-group-row_button .button:last-child {
  margin-right: 0;
}

.noise_section .input:disabled {
  background: var(--color-bg-input);
  color: var(--color-fg-input);
  border: none;
  opacity: 0.5;
}

.noise_checkbox {
  margin-top: 30px;
  display: flex;
}

.noise_button {
  max-width: 100%;
  flex-basis: 120px;
  flex-grow: 1;
}

.noise_map-marker {
  position: absolute;
  bottom: -5px;
  left: -10px;
}

.noise_map-icon {
  height: 40px;
}

.noise_map-icon path {
  stroke: var(--color-map-marker-dark);
  fill: var(--color-map-marker-dark);
}
.noise_map-icon path:first-child {
  fill: var(--color-map-marker-light);
}

.noise_map-text {
  font-size: 14px;
  font-weight: 500;
  font-family: 'neo-sans', sans-serif;
  min-width: 200px;
  max-width: 400px;
  text-align: center;
  padding: 5px;
  background: var(--color-fg-base);
  color: var(--color-bg-base);
  box-shadow: 3px 5px 14px 2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 40px;
}

.custom-marker {
  position: relative;
}

.noise__row-field {
  margin-right: 0;
  width: 135px;
}

.noise__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.noise__progress .form__reminder {
  color: var(--color-fg-form-field-label);
}

.noise__progress .form__reminder {
  min-height: 0;
}

.progress__spinner {
  transform: scale(0.1);
}
/* TODO: in future if there will be other MAP_CONTROLS width, it should be adjustable or calculated */
.sidebar-modal_open .leaflet-control,
.sidebar-modal_open img[alt='Google'] {
  transform: translateX(76px);
}

@media screen and (max-height: 968px) {
  .noise_section {
    margin: 10px 0 0;
  }

  .noise_section:last-child {
    margin-bottom: 10px;
  }

  .noise_section .form-group {
    margin-bottom: 0px;
  }

  .noise_section .formfield__select {
    margin-bottom: 12px;
  }

  .noise_header {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
